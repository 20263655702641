import * as React from "react"
// import Layout from "../components/layout"
import Seo from "../components/seo"

const Services = () => {
  return (
    <div>
      <Seo
        title="Creative Services"
        description="One part creative studio, one part engineering lab. Budmen offers a variety of services to help you realize your ideas and grow your business."
      />
      <div className="bg-zinc-100 dark:bg-zinc-900 ">
        <section
          className=" "
          style={{
            backgroundImage:
              "url(https://cdn.sanity.io/images/rbhjt84j/production/352c18789850e5618d512b13fe6739466df868a5-5184x3456.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className=" px-8 py-12  bg-black/[0.6] text-white sm:shadow-lg">
            <h1 className="lg:text-5xl md:text-3xl text-2xl font-bold mb-4 ">
              Bring your ideas to life with Budmen
            </h1>
            <p className="text-lg max-w-prose">
              One part creative studio, one part engineering lab. We believe the
              best products come from a collaborative approach to creativity and
              technology. To share that approach, we offer a variety of services
              to help you realize your ideas and grow your business.
            </p>
          </div>
        </section>

        <div className="flex lg:flex-row flex-col justify-center	">
          <section
            style={{
              backgroundImage: "url(/project-launcher-bg.png)",
              backgroundSize: "cover",
            }}
            className=" w-full text-white text-center "
          >
            <div className="w-full h-full  bg-black/[0.9]  flex justify-center lg:items-center">
              <div className="w-full py-12 text-center px-4 sm:px-6 lg:py-12 lg:px-8 z-20">
                <div className="text-md font-bold mb-4 tracking-widest text-center dark:text-zinc-300">
                  <svg
                    className="mr-2 mb-1 h-8 fill-white text-center dark:fill-zinc-300 inline-block"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0"
                    y="0"
                    enableBackground="new 0 0 594 252"
                    version="1.1"
                    viewBox="0 0 594 252"
                    xmlSpace="preserve"
                  >
                    <path d="M240.8 103.3H236v43.3h4.5c12 0 22.2-6.6 22.2-21.6.1-13.9-8.9-21.7-21.9-21.7zM297 11.2L12 57.6v136.8l285 46.4 285-46.4V57.6L297 11.2zM97.8 163.6h-31V86.3h28.7c13.7 0 23 5.3 23 20.1 0 7-2.3 12.6-8.5 15.8v.2c11.1 1.4 15.8 8.9 15.8 19.7 0 16.2-13.9 21.5-28 21.5zm105.4-33.8c0 10-.4 19.8-8.1 27.3-6.5 6.4-16.6 8.6-25.6 8.6s-19.2-2.2-25.7-8.6c-7.7-7.5-8.1-17.3-8.1-27.3V86.3h20.1V127c0 10.1.7 21 13.6 21s13.6-10.9 13.6-21V86.3h20.1v43.5h.1zm41.2 33.8h-28.5V86.3h28.5c21.5 0 39.2 16.9 39.2 38.7 0 21.7-17.8 38.6-39.2 38.6zm120.7 0l-5.6-44.5h-.2l-18.6 44.5h-8L315 119.1h-.2l-6.5 44.5h-20l13.1-77.3h19.8l15.7 41.3 16.6-41.3h20l11.7 77.3h-20.1zm72.5-60.3h-23.9v12.9h22.7v17h-22.7v13.3h23.9v17h-44V86.2h44v17.1zm89.6 60.3h-20.1l-36.8-47.4h-.2v47.4H450V86.3h20.1l36.8 47.3h.2V86.3h20.1v77.3zM105.4 140c0 8.5-10.1 8.2-16 8.2h-2.5v-16.6h2c6.6 0 16.5-.9 16.5 8.4zm-16.3-23.3h-2.2v-15h1.4c5.4 0 11.7.4 11.7 7.5 0 6.5-5.5 7.5-10.9 7.5z"></path>
                  </svg>
                  DESIGN LAB
                </div>
                <h2 className="font-extrabold text-center my-4 text-white">
                  <span className="block lg:text-3xl md:text-xl text-lg  font-medium">
                    Have an impossible idea?
                  </span>
                  <span className="block lg:text-3xl md:text-xl text-lg  text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-green-400 to-pink-600">
                    We specialize in impossible.
                  </span>
                  <p className="font-medium mt-4 w-5/12 m-auto">
                    Have a wild idea? Work with us to make it a reality! From
                    napkin sketch to production - we will help you create,
                    refine and realize your imagination.
                  </p>
                </h2>
                <div className="md:mt-6 inline-flex rounded-md shadow justify-center">
                  <a
                    href="https://budmen.services"
                    target="_blank"
                    rel="noreferrer"
                    className="flex-shrink-0 text-white bg-purple-500 border-0 py-2 px-4 focus:outline-none hover:bg-purple-600 rounded font-bold tracking-widest text-xs uppercase"
                  >
                    Start Your Project
                  </a>
                </div>
              </div>
            </div>
          </section>
          {/* <section
            style={{
              backgroundImage:
                "url(https://cdn.sanity.io/images/rbhjt84j/production/34596028c630bb06b798a1e5781e1a4df8bfde46-2101x1501.png)",
              backgroundSize: "cover",
            }}
            className=" lg:w-1/2 w-full text-white  "
          >
            <div className="w-full h-full bg-white/[0.5] dark:bg-black/[0.65] relative lg:flex lg:items-center">
              <div className="w-full py-12 text-left px-4 sm:px-6 lg:py-12 lg:px-8 z-20">
                <img
                  className="w-2/3"
                  src="https://cdn.sanity.io/images/rbhjt84j/production/90253da8ff1e99b5456b6db96d60259c972b33a1-4787x857.png"
                  alt="LaserCatLaser written in magenta, cyan and black letters next to an icon of cat with laser eyes"
                />
                <h2 className="lg:text-lg md:text-md my-4 text-black dark:text-white">
                  The easiest way to create custom laser engraved wood products.
                  Design custom pins, keychains and magnets -- as simple as
                  point and click.
                </h2>
                <div className="md:mt-6 inline-flex rounded-md shadow">
                  <a
                    href="https://lasercatlaser.com"
                    target="_blank"
                    rel="noreferrer"
                    className="flex-shrink-0 text-white bg-[#ff00ff] border-0 py-2 px-4 focus:outline-none hover:opacity-80 rounded font-bold tracking-widest text-xs uppercase"
                  >
                    Create Your Design
                  </a>
                </div>
              </div>
            </div>
          </section> */}
        </div>
      </div>
    </div>
  )
}
export default Services
